import { STATIC_PATH } from "_styles";

const ICONS_PATH = `${STATIC_PATH}/Icons/`;

// 특성이 있는 아이콘은 특성으로 그룹화 나머지는 컬러로 구분
const icons = {
  warning: {
    warning_20: ICONS_PATH + "ic_warning_20.png",
  },
  error: {
    error_20: ICONS_PATH + "ic_error_20.png",
  },
  profile: {
    profile_44: ICONS_PATH + "profile.png",
  },

  close: {
    bgray400_12: ICONS_PATH + "ic_close_default_bgray400_12.png",
    bgray400_16: ICONS_PATH + "ic_close_default_bgray400_16.png",
    bgray200_12: ICONS_PATH + "ic_close_circle_default_bgray200_12.png",
    bgray200_24: ICONS_PATH + "ic_close_default_bgray200_24.png",
    bgray500_24: ICONS_PATH + "ic_close_default_bgray500_24.png",
    circle_bgray200_14: ICONS_PATH + "ic_close_circle_default_bgray200_12.png",
  },
  delete: {
    white_16: ICONS_PATH + "ic_delete_default_white_16.png",
    bgray500_16: ICONS_PATH + "ic_delete_deafult_bgray500_16.png",
    white_20: ICONS_PATH + "ic_delete_default_white_20.png",
    bgray200_20: ICONS_PATH + "ic_delete_deafult_bgray200_20.png",
    bgray500_20: ICONS_PATH + "ic_delete_deafult_bgray500_20.png",
    egray400_20: ICONS_PATH + "ic_delete_default_egray400_20.png",
    bgray500_24: ICONS_PATH + "ic_delete_deafult_bgray500_24.png",
  },
  edit: {
    white_20: ICONS_PATH + "ic_edit_default_white_20.png",
    bgray200_20: ICONS_PATH + "ic_edit_default_bgray200_20.png",
    bgray500_20: ICONS_PATH + "ic_edit_default_bgray500_20.png",
  },
  check: {
    white_16: ICONS_PATH + "ic_check_default_white_16.png",
    main_20: ICONS_PATH + "ic_check_default_main_20.png",
    checked_20: ICONS_PATH + "ic_checked_20.png",
  },
  reload: {
    bgray400_20: ICONS_PATH + "ic_reload_default_bgray400_20.png",
    bgray400_24: ICONS_PATH + "ic_reload_default_bgray400_24.png",
  },
  search: {
    bgray400_20: ICONS_PATH + "ic_search_default_bgray400_20.png",
    egray400_20: ICONS_PATH + "ic_search_default_egray400_20.png",
    bgray400_24: ICONS_PATH + "ic_search_default_bgray400_24.png",
  },
  document: {
    bgray200_20: ICONS_PATH + "ic_document_default_bgray200_20.png",
    bgray400_20: ICONS_PATH + "ic_document_default_bgray400_20.png",
    bgray500_16: ICONS_PATH + "ic_add_default_bgray500_16.png",
    white_20: ICONS_PATH + "ic_add_default_white_20.png",
    main_20: ICONS_PATH + "ic_add_default_main_20.png",
    egray400_24: ICONS_PATH + "ic_add_default_egray400_24.png",
  },
  upload: {
    white_20: ICONS_PATH + "ic_upload_default_white_20.png",
    main_20: ICONS_PATH + "ic_uplo ad_default_main_20.png",
  },
  bookmark: {
    24: {
      off: ICONS_PATH + "ic_bookmark_20.png",
      hover: ICONS_PATH + "ic_bookmark_hover_main32dp_24.png",
      on: ICONS_PATH + "ic_bookmark_on_main_24.png",
    },
    20: {
      off: ICONS_PATH + "ic_bookmark_off_white_20.png",
      hover: ICONS_PATH + "ic_bookmark_hover_main32dp_24.png",
      on: ICONS_PATH + "ic_bookmark_on_main_24.png",
    },
  },
  eye: {
    off: ICONS_PATH + "ic_eyeoff_default_bgray500_20.png",
    on: ICONS_PATH + "ic_eye_default_bgray500_20.png",
  },
  hamburger: {
    bgray500_16: ICONS_PATH + "ic_hamburger_default_bgray500_16.png",
    egray400_16: ICONS_PATH + "ic_hamburger_default_egray400_16.png",
  },
  list: {
    bgray400_16: ICONS_PATH + "ic_list_default_bgray400_16.png",
    main_16: ICONS_PATH + "ic_list_default_main_16.png",
    egray400_16: ICONS_PATH + "ic_list_default_egray400_16.png",
  },
  grid: {
    off: ICONS_PATH + "ic_columns_off_bgray400_16.png",
    on: ICONS_PATH + "ic_columns_on_egray400_16.png",
  },
  info: {
    default: ICONS_PATH + "ic_info_default_bgray400_16.png",
    bold: ICONS_PATH + "ic_info_bold_default_bgray400_16.png",
    info_20: ICONS_PATH + "ic_info_20.png",
    semi: ICONS_PATH + "ic_info_semi_default_egray400_16.png",
  },
  plus: {
    egray400_16: ICONS_PATH + "ic_plus_default_egray400_16.png",
    bgray200_16: ICONS_PATH + "ic_plus_default_bgray200_16.png",
    white_20: ICONS_PATH + "ic_plus_default_white_20.png",
    bgray200_20: ICONS_PATH + "ic_plus_default_bgray200_20.png",
    egray400_20: ICONS_PATH + "ic_plus_default_egray400_20.png",
  },
  datasetInfo: {
    maximise: ICONS_PATH + "ic_maximise_default_bgray400_16.png",
    save: ICONS_PATH + "ic_save_default_bgray400_16.png",
    package: ICONS_PATH + "ic_package_default_bgray400_16.png",
    server: ICONS_PATH + "ic_server_default_bgray400_16.png",
    clock: ICONS_PATH + "ic_clock_default_bgray400_16.png",
    clock_forward: ICONS_PATH + "ic_clock-forward_default_bgray400_16.png",
    clock_check: ICONS_PATH + "ic_clock-check_default_bgray400_16.png",
    upload: ICONS_PATH + "ic_upload_default_bgray400_16.png",
  },
  star: {
    off: ICONS_PATH + "ic_star_off_bgray200_18.png",
    on: ICONS_PATH + "ic_star_on_main_18.png",
  },
  calendar: {
    off: ICONS_PATH + "ic_calendar_off_bgray500_24.png",
    on: ICONS_PATH + "ic_calendar_on_main_24.png",
  },
  link: {
    main_20: ICONS_PATH + "ic_link_default_main_20.png",
    bgray500_20: ICONS_PATH + "ic_link_default_bgray500_20.png",
    bgray500_24: ICONS_PATH + "ic_link_default_bgray500_24.png",
    bgray200_28: ICONS_PATH + "ic_link_default_bgray200_28.png",
    main_28: ICONS_PATH + "ic_link_default_main_28.png",
    bgray100_24: ICONS_PATH + "ic_link_default_bgray100_24.png",
  },
  arrow: {
    reverse: {
      right: ICONS_PATH + "ic_reverse_right_default_bgray400_24.png",
      left: ICONS_PATH + "ic_reverse_left_default_bgray400_24.png",
    },
    left: ICONS_PATH + "ic_arrow_left_default_bgray400_24.png",
    right: ICONS_PATH + "ic_arrow_right_default_bgray400_24.png",
    up: {
      bgray200_20: ICONS_PATH + "ic_arrow_up_default_bgray200_20.png",
      main_20: ICONS_PATH + "ic_arrow_up_default_main_20.png",
      egray400_20: ICONS_PATH + "ic_arrow_up_default_egray400_20.png",
    },
    down: {
      default6_20: ICONS_PATH + "ic_arrow_down_default6_20.png",
      main_20: ICONS_PATH + "ic_arrow_down_default_main_20.png",
      egray400_20: ICONS_PATH + "ic_arrow_down_default_egray400_20.png",
    },
  },
  snb: {
    match: {
      egray400_16: ICONS_PATH + "ic_match_default_egray400_16.png",
      bgray500_16: ICONS_PATH + "ic_match_default_bgray500_16.png",
    },
    cloud: {
      egray400_16: ICONS_PATH + "ic_cloudupload_default_egray400_16.png",
      bgray500_16: ICONS_PATH + "ic_cloudupload_default_bgray500_16.png",
    },
    database: {
      egray400_16: ICONS_PATH + "ic_database_default_egray400_16.png",
      bgray500_16: ICONS_PATH + "ic_database_default_bgray500_16.png",
    },
    home: {
      egray400_16: ICONS_PATH + "ic_home_default_egray400_16.png",
      bgray500_16: ICONS_PATH + "ic_home_default_bgray500_16.png",
    },
    bridge: {
      egray400_16: ICONS_PATH + "ic_bridge_default_egray400_16.png",
      bgray500_16: ICONS_PATH + "ic_bridge_default_bgray500_16.png",
    },
  },

  chevron: {
    left: {
      bgray400_12: ICONS_PATH + "ic_chevron_left_default_bgray400_12.png",
      bgray100_16: ICONS_PATH + "ic_chevron_left_default_bgray100_16.png",
      bgray200_16: ICONS_PATH + "ic_chevron_left_default_bgray200_16.png",
      bgray400_16: ICONS_PATH + "ic_chevron_left_default_bgray400_16.png",
      egray400_16: ICONS_PATH + "ic_chevron_left_default_egray400_16.png",
      bgray400_24: ICONS_PATH + "ic_chevron_left_default_bgray400_24.png",
    },
    up: {
      bgray400_12: ICONS_PATH + "ic_chevron_up_default_bgray400_12.png",
      bgray100_16: ICONS_PATH + "ic_chevron_up_default_bgray100_16.png",
      bgray200_16: ICONS_PATH + "ic_chevron_up_default_bgray200_16.png",
      bgray400_16: ICONS_PATH + "ic_chevron_up_default_bgray400_16.png",
      egray400_16: ICONS_PATH + "ic_chevron_up_default_egray400_16.png",
      bgray400_24: ICONS_PATH + "ic_chevron_up_default_bgray400_24.png",
    },
    down: {
      bgray400_12: ICONS_PATH + "ic_chevron_down_default_bgray400_12.png",
      bgray100_16: ICONS_PATH + "ic_chevron_down_default_bgray100_16.png",
      bgray200_16: ICONS_PATH + "ic_chevron_down_default_bgray200_16.png",
      bgray400_16: ICONS_PATH + "ic_chevron_down_default_bgray400_16.png",
      egray400_16: ICONS_PATH + "ic_chevron_down_default_egray400_16.png",
      bgray400_24: ICONS_PATH + "ic_chevron_down_default_bgray400_24.png",
    },
    right: {
      bgray400_12: ICONS_PATH + "ic_chevron_right_default_bgray400_12.png",
      bgray100_16: ICONS_PATH + "ic_chevron_right_default_bgray100_16.png",
      bgray200_16: ICONS_PATH + "ic_chevron_right_default_bgray200_16.png",
      bgray400_16: ICONS_PATH + "ic_chevron_right_default_bgray400_16.png",
      egray400_16: ICONS_PATH + "ic_chevron_right_default_egray400_16.png",
      bgray400_24: ICONS_PATH + "ic_chevron_right_default_bgray400_24.png",
    },
  },
  chevrons: {
    left: {
      bgray200_16: ICONS_PATH + "ic_chevrons_left_default_bgray200_16.png",
    },
    right: {
      bgray200_16: ICONS_PATH + "ic_chevrons_right_default_bgray200_16.png",
    },
  },
  download: {
    bgray500_20: ICONS_PATH + "ic_download_default_bgray500_20.png",
    bgray400_20: ICONS_PATH + "ic_download_default_bgray400_20.png",
    white_20: ICONS_PATH + "ic_download_default_white_20.png",
  },
  // non-categorized
  lock: ICONS_PATH + "ic_lock_default_bgray400_16.png",
  img: ICONS_PATH + "ic_img_default_white_20.png",
  etc: ICONS_PATH + "ic_etc_default_bgray400_24.png",
  bell: ICONS_PATH + "ic_bell_default_bgray400_24.png",
  design: ICONS_PATH + "ic_Design_default_bgray400_24.png",
  filter: ICONS_PATH + "ic_filter_default_bgray400_24.png",
  enter: ICONS_PATH + "ic_enter_default_bgray200_28.png",
  minus: ICONS_PATH + "ic_minus.png",
  twinkle: ICONS_PATH + "ic_twinkle.png",
  play: ICONS_PATH + "ic_play_default_bgray400_20.png",
  angle: ICONS_PATH + "ic_arrows_angle.png",
  reflect: {
    vertical: ICONS_PATH + "ic_reflect_vertical.png",
    horizontal: ICONS_PATH + "ic_reflect_horizontal.png",
  },
  cut_tool: {
    rb: ICONS_PATH + "ic_cut_tool_rb.png",
    lb: ICONS_PATH + "ic_cut_tool_lb.png",
    rt: ICONS_PATH + "ic_cut_tool_rt.png",
    lt: ICONS_PATH + "ic_cut_tool_lt.png",
  },
  animal: ICONS_PATH + "ic_animal.png",
  face: ICONS_PATH + "ic_face.png",
  body: ICONS_PATH + "ic_body.png",
  point: ICONS_PATH + "ic_point.png",
  cuboid: ICONS_PATH + "ic_cuboid.png",
  polyfline: ICONS_PATH + "ic_polyline.png",
  polygon: ICONS_PATH + "ic_polygon.png",
  bounding_box: ICONS_PATH + "ic_boundingbox.png",
  chroma: ICONS_PATH + "ic_chroma.png",
  contrast: ICONS_PATH + "ic_contrast.png",
  brightness: ICONS_PATH + "ic_brightness.png",
  cut: ICONS_PATH + "ic_cut.png",
  move: ICONS_PATH + "ic_move.png",
  edit_stick: ICONS_PATH + "ic_editstick.png",
  text: ICONS_PATH + "ic_text.png",
  cursor: ICONS_PATH + "ic_cursor.png",
  hand: ICONS_PATH + "ic_hand.png",
};

const illust = {
  openbox: ICONS_PATH + "illust/il_openbox_120px.png",
  engagement: ICONS_PATH + "illust/il_engagement_36.png",
  warning: ICONS_PATH + "illust/il_warning_Bgray800_40.png",
  info: ICONS_PATH + "illust/il_info_Gray300_40.png",
  noresearch: ICONS_PATH + "illust/il_noresearch_150.png",
  email: ICONS_PATH + "illust/il_email_65.png",
  oci: {
    20: ICONS_PATH + "illust/il_oci.png",
    60: ICONS_PATH + "illust/il_oracle_60.png",
  },
  aws: {
    20: ICONS_PATH + "illust/il_aws.png",
    60: ICONS_PATH + "illust/il_aws_60.png",
  },
  azure: {
    20: ICONS_PATH + "illust/il_azure.png",
    60: ICONS_PATH + "illust/il_azure_60.png",
  },
  google: {
    logo: ICONS_PATH + "illust/ic_GoogleLogo_20.png",
    20: ICONS_PATH + "illust/il_google.png",
    60: ICONS_PATH + "illust/il_google_60.png",
  },
  apple: ICONS_PATH + "illust/ic_AppleLogo_20.png",
  hwp: ICONS_PATH + "illust/il_hwp_16.png",
  csv: ICONS_PATH + "illust/il_csv_16.png",
  doc: ICONS_PATH + "illust/il_doc_16.png",
  jpg: ICONS_PATH + "illust/il_jpg_16.png",
  png: ICONS_PATH + "illust/il_png_16.png",
  psd: ICONS_PATH + "illust/il_psd_16.png",
  css: ICONS_PATH + "illust/il_css_16.png",
  txt: ICONS_PATH + "illust/il_txt_16.png",
  ppt: ICONS_PATH + "illust/il_ppt_16.png",
  pdf: ICONS_PATH + "illust/il_pdf_16.png",
  video: ICONS_PATH + "illust/il_video_16.png",
  mp3: ICONS_PATH + "illust/il_mp3_16.png",
  sql: ICONS_PATH + "illust/il_sql_16.png",
  svg: ICONS_PATH + "illust/il_svg_16.png",
  js: ICONS_PATH + "illust/il_js_16.png",
  ai: ICONS_PATH + "illust/il_ai_16.png",
  sml: ICONS_PATH + "illust/il_sml_16.png",
  heml: ICONS_PATH + "illust/il_heml_16.png",
  xls: ICONS_PATH + "illust/il_xls_16.png",
  folder: {
    36: ICONS_PATH + "illust/il_folder_36.png",
    16: {
      gray_16: ICONS_PATH + "illust/il_folder_gray_16.png",
      yellow_16: ICONS_PATH + "illust/il_folder_yellow_16.png",
    },
    zip: ICONS_PATH + "illust/il_folder_zip_16.png",
  },
};

export { icons, illust };
